import axios from "axios";

const getIp = async () => {
  // https://www.google.com/search?sxsrf=ALeKk02K_5PcJQmeU9CpQkAv49WeJkPoyw%3A1591239358039&ei=vmLYXtSGAt2GytMPiLmKsAs&q=javascript+get+user%27s+ip+address&oq=javascript+get+user%27s+ip+address&gs_lcp=CgZwc3ktYWIQAzICCAAyBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB46BAgjECc6BAgAEEM6BQgAELEDOgUIABCDAToFCAAQkQI6BwgAELEDEEM6CAgAELEDEJECOgcIABAUEIcCUOkSWIFxYMlyaABwAHgAgAGNBIgBuRqSAQwyNC4zLjIuMS4wLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=psy-ab&ved=0ahUKEwjU3cDFlOfpAhVdg3IEHYicArYQ4dUDCAw&uact=5#kpvalbx=_bGPYXoiyNcGpytMPneCa-A824
  const url = `https://api.ipify.org/?format=json`;

  try {
    const { data } = await axios.get(url);
    // console.log("getIp - data: ", data);
    return data.ip;
  } catch (err) {
    console.log(err.message);
  }
};

// gets ip information from API
const getIpInfo = async (ip) => {
  // console.log("GOT HERE!!!!!", ip);
  let ipifyApiKey = process.env.VUE_APP_IPIFY_API_KEY;
  try {
    const data = await axios.get(
      `https://geo.ipify.org/api/v1?apiKey=` + ipifyApiKey + `&ipAddress=` + ip
    );
    const result = data.data;
    return result;

    // ORIG: at_FBTi0rGPhQlKp2cRdOJV0t3Mibq6F
    // at_al7BsZVu56xSENWm2lWxsnEts0iPX
    // const data = await axios.get(
    //   `https://geo.ipify.org/api/v1?apiKey=at_al7BsZVu56xSENWm2lWxsnEts0iPX&ipAddress=` +
    //     ip
    // );

    // ipInfo.value = {
    //   address: result.ip,
    //   state: result.location.region,
    //   timezone: result.location.timezone,
    //   isp: result.isp,
    //   lat: result.location.lat,
    //   lng: result.location.lng,
    // };
    // return ipInfo.value;

    // leaflet.marker([ipInfo.value.lat, ipInfo.value.lng]).addTo(mymap);
    // mymap.setView([ipInfo.value.lat, ipInfo.value.lng], 13);
  } catch (err) {
    alert(err.message);
  }
};

export { getIp, getIpInfo };
