<template>
  <div class="spadefootquiz">
    <div class="sectionHeader">Welcome to the Spadefoot Quiz</div>
    <p>
      Click or tap on the image(s) you think are eastern spadefoots (located in
      Eastern Massachusetts).
    </p>

    <div>
      <button
        type="button"
        class="btn btn-success"
        v-on:click="_checkResults"
        v-if="showDoneBtn"
      >
        Done
      </button>
      <button
        id="keyPadCancel"
        class="btn btn-primary"
        v-on:click="_startOver"
        v-if="showStartOverBtn"
      >
        Start Over
      </button>

      <!-- <button @click="$router.push('/creekroad')" class="btn btn-warning">
          Back to Home
        </button> -->
    </div>

    <div class="selection">
      <h5>{{ displayMessage }}</h5>
    </div>

    <div class="gal">
      <img
        src="@/assets/images/creekroadcons/SpadefootQuiz/SpadefootQuiz_A.png"
        class="img-fluid"
        alt="Spadefoot Quiz Image A"
        @click="_registerKeyPress"
        data-args="A"
        v-bind:class="`A` | _calcLivingAreaDiff(selectedImages, selectedImage)"
      />

      <img
        src="@/assets/images/creekroadcons/SpadefootQuiz/SpadefootQuiz_B.png"
        class="img-fluid"
        alt="Spadefoot Quiz Image B"
        @click="_registerKeyPress"
        data-args="B"
        v-bind:class="`B` | _calcLivingAreaDiff(selectedImages, selectedImage)"
      />

      <img
        src="@/assets/images/creekroadcons/SpadefootQuiz/SpadefootQuiz_C.png"
        class="img-fluid"
        alt="Spadefoot Quiz Image C"
        @click="_registerKeyPress"
        data-args="C"
        v-bind:class="`C` | _calcLivingAreaDiff(selectedImages, selectedImage)"
      />

      <img
        src="@/assets/images/creekroadcons/SpadefootQuiz/SpadefootQuiz_D.png"
        class="img-fluid"
        alt="Spadefoot Quiz Image D"
        @click="_registerKeyPress"
        data-args="D"
        v-bind:class="`D` | _calcLivingAreaDiff(selectedImages, selectedImage)"
      />
    </div>

    <!-- <div>
        <h5>{{ selectedImages }}</h5>
      </div> -->

    <div v-if="showFindingSpadefoots">
      <FindingSpadefoots></FindingSpadefoots>
    </div>

    <div class="spacer"></div>

    <!-- <button
            type="button"
            class="btn btn-outline-secondary"
            @click="_registerKeyPress"
            data-args="A"
          >
            A
          </button> -->
  </div>
</template>

<script>
import FindingSpadefoots from "@/components/EasternSpadefoots/FindingSpadefoots.vue";

export default {
  name: "spadefootquiz",
  data() {
    return {
      selectedImages: "",
      displayMessage: "",
      selectedImage: "",
      showFindingSpadefoots: false,
      showDoneBtn: false,
      showStartOverBtn: false,
    };
  },

  components: {
    FindingSpadefoots,
  },

  mounted: function () {
    this.$nextTick(function () {
      jQuery(".spadefootquiz").hide();
      $(".spadefootquiz").fadeIn("slow");
    });
  },

  methods: {
    _registerKeyPress(e) {
      var _selectedImage = e.target.getAttribute("data-args").split(",");
      // console.log("_registerKeyPress args:", args);
      var _selectedImages = this.selectedImages;
      this.selectedImage = _selectedImage;
      this.displayMessage = "";

      // set up first time button display
      if (!this.showStartOverBtn) {
        this.showStartOverBtn = true;
      }
      if (!this.showDoneBtn) {
        this.showDoneBtn = true;
      }

      if (_selectedImages.includes(_selectedImage)) {
        // console.log("Already selected");
        // remove the selected image from all the selectedImages to create a toggle effect
        _selectedImages = _selectedImages.replace(_selectedImage, "");
        this.selectedImages = _selectedImages;
      } else {
        this.selectedImages = _selectedImages + _selectedImage;
        // console.log("selectedImages", this.selectedImages);
        if (
          this.selectedImages.includes("A") &&
          this.selectedImages.includes("B") &&
          this.selectedImages.includes("D") &&
          !this.selectedImages.includes("C")
        ) {
          this.displayMessage =
            "Congratulations! You found all the spadefoots...";
          this.showFindingSpadefoots = true;
          this.showDoneBtn = false;
        } else {
          if (this.selectedImages.includes("C")) {
            this.displayMessage = "Sorry, that's a juvenile American bullfrog";
            this.showDoneBtn = false;
          }
          // this.selectedImages = "Keep going!";
        }
        return "Added";
      }
    },

    _startOver(e) {
      this.selectedImages = "";
      this.displayMessage = "";
      this.showFindingSpadefoots = false;
      this.showDoneBtn = true;
      // this.$store.dispatch("updateStreetNumber", "");
      // this.$store.dispatch("clearValues", "");
    },

    _checkResults(e) {
      // this.$store.dispatch("getStreetNamesByStreetNum", this.selectedImages);
      if (
        this.selectedImages.includes("A") &&
        this.selectedImages.includes("B") &&
        this.selectedImages.includes("D") &&
        !this.selectedImages.includes("C")
      ) {
        this.selectedImages = "";
        this.displayMessage = "";
        this.showFindingSpadefoots = true;
      } else {
        this.displayMessage = "Keep going!";
      }
    },

    async logUserSpadefootQuiz() {
      // console.log("logSpadefootQuiz");
      // this.$store.dispatch("logSpadefootQuiz");
    },
  },

  created: function () {
    // console.log("Created");
    this.logUserSpadefootQuiz();
  },

  filters: {
    _calcLivingAreaDiff: function (SelectedImage, selectedImages) {
      // console.log(
      //   "SelectedImage, selectedImages: ",
      //   SelectedImage,
      //   selectedImages
      // );

      var currentKeyPadValue = selectedImages;
      // console.log("selectedImages: ", selectedImages);

      let rng = "";

      if (selectedImages.includes(SelectedImage)) {
        if (SelectedImage == "C") {
          rng = "Incorrect";
        } else {
          rng = "Correct";
        }
      } else {
        rng = "Default";
      }

      return rng;
    },
  },
};
</script>

<style scoped>
.spacer {
  margin-bottom: 2rem;
}

.sectionHeader {
  font-size: larger;
  font-weight: bold;
  padding: 0.4rem;
  margin-bottom: 1rem;
  color: white;
  background-color: darkorange;
}

button {
  margin-right: 1rem;
}

.project.Renovated {
  border-left: 4px solid #3cd1c2;
}

.project.PartialRenov {
  border-left: 4px solid orange;
}

.project.NeedsWorkLoc {
  border-left: 4px solid tomato;
}

.Default {
  border-left: 8px solid #3cd1c2;
}

.Correct {
  border-left: 8px solid orange;
}

.Incorrect {
  border-left: 8px solid tomato;
}

.mobile-image {
  display: none;
}

@media only screen and (max-width: 768px) {
  .full-image {
    display: none;
  }

  .mobile-image {
    display: block;
  }
}

.gal {
  margin-top: 1rem;
  -webkit-column-count: 2;
  /* Chrome, Safari, Opera */
  -moz-column-count: 2;
  /* Firefox */
  column-count: 2;
}

.gal img {
  width: 100%;
  padding: 0px 0;
  margin-bottom: 1rem;
}

.gal h2 {
  margin: 0 0 0;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: #fbb315;
}

.gal .request-detail {
  border: #fbb315;
  border-style: solid;
  border-width: 1px;
  padding: 4px;
  margin-bottom: 10px;
}

.gal p {
  margin: 10px 0 0;
  padding: 0;
  font-size: 14px;
}

.gal .request-date {
  font-size: 14px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .gal {
    -webkit-column-count: 1;
    /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    /* Firefox */
    column-count: 1;
  }
}

@media (max-width: 480px) {
  .gal {
    -webkit-column-count: 1;
    /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    /* Firefox */
    column-count: 1;
  }
}
</style>
