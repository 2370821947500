<template>
  <div class="creekroad">
    <div class="heading" id="creekroadtop">
      <h1>Provincetown &mdash; <i>Creek Road Conservation Area</i></h1>
      <h4>
        <i
          >Open Space in the Creek Road/West Vine Street Area of Provincetown</i
        >
      </h4>
    </div>

    <div class="full-image">
      <img
        src="../assets/images/creekroadcons/CreekRoadAreaMapsOnlineHabitatWet_1.png"
        class="img-fluid"
        alt="Wetlands"
      />
    </div>

    <div class="mobile-image">
      <img
        src="../assets/images/creekroadcons/CreekRoadAreaMapsOnlineHabitatWetMobile_1.png"
        class="img-fluid"
        alt="Wetlands"
      />
    </div>

    <Introduction></Introduction>

    <CreekRoadConsPropertyMapKey></CreekRoadConsPropertyMapKey>

    <CreekRoadSpadefootHistory></CreekRoadSpadefootHistory>

    <CreekRoadOtherViews></CreekRoadOtherViews>

    <!-- <StreetViewBlueberryCreek></StreetViewBlueberryCreek> -->

    <PropertyDetails></PropertyDetails>

    <Resources></Resources>

    <ProvincetownConservationBylaws></ProvincetownConservationBylaws>

    <div class="footerSpacing"></div>

    <a id="back2Top" title="Back to top" href="#">&#10148;</a>
  </div>
</template>

<script>
import Introduction from "../components/CreekRoadConsv/CreekRoadConsIntroduction.vue";
import CreekRoadSpadefootHistory from "@/components/CreekRoadConsv/CreekRoadConsSpadefootHistory.vue";
import CreekRoadConsPropertyMapKey from "@/components/CreekRoadConsv/CreekRoadConsPropertyMapKey.vue";
import CreekRoadOtherViews from "@/components/CreekRoadConsv/CreekRoadConsOtherViews.vue";
import SpadefootToad from "@/components/CreekRoadConsv/SpadefootToad.vue";
// import StreetViewBlueberryCreek from "@/components/OpenSpace/StreetViewBlueberryCreek.vue";
import Resources from "@/components/EasternSpadefoots/Resources.vue";
import PropertyDetails from "@/components/CreekRoadConsv/CreekRoadConsPropertyDetails.vue";
import ProvincetownConservationBylaws from "@/components/CreekRoadConsv/ProvincetownConservationBylaws.vue";
import SpadefootQuiz from "@/components/EasternSpadefoots/SpadefootQuiz.vue";

export default {
  data() {
    return {
      somedataelement: "",
    };
  },
  components: {
    Introduction,
    CreekRoadSpadefootHistory,
    CreekRoadConsPropertyMapKey,
    CreekRoadOtherViews,
    SpadefootToad,
    PropertyDetails,
    Resources,
    ProvincetownConservationBylaws,
    SpadefootQuiz,
  },
  computed: {
    someFunction() {
      return "someFunction";
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      jQuery(".creekroad").hide();
      $(".creekroad").fadeIn("slow");
    });

    /*Scroll to top when arrow up clicked BEGIN*/
    $(window).scroll(function () {
      var height = $(window).scrollTop();
      if (height > 100) {
        $("#back2Top").fadeIn();
      } else {
        $("#back2Top").fadeOut();
      }
    });
    $(document).ready(function () {
      $("#back2Top").click(function (event) {
        event.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
      });
    });
    /*Scroll to top when arrow up clicked END*/
  },

  methods: {},
};
</script>

<style scoped>
.heading {
  color: #fff;
  background-color: steelblue;
  padding: 1em;
}
/* https://stackoverflow.com/questions/27853884/media-queries-and-image-swapping/27853917 */

.mobile-image {
  display: none;
}

@media only screen and (max-width: 768px) {
  .full-image {
    display: none;
  }

  .mobile-image {
    display: block;
  }
}

.card {
  margin-bottom: 1rem;
}

.footerSpacing {
  height: 2rem;
}
</style>
