import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
// import store from "./store";
import store from "./store/index";

import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Axios from "axios";
import moment from "moment";

import "./assets/index.css";

import * as firebase from "firebase";

import jQuery from "jquery";
global.jQuery = jQuery;
global.$ = jQuery;

Vue.use(BootstrapVue);
Vue.prototype.$http = Axios;
Vue.prototype.moment = moment;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    firebase.initializeApp({
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_PROJECT_ID + ".firebaseapp.com",
      databaseURL:
        "https://" +
        process.env.VUE_APP_FIREBASE_PROJECT_ID +
        ".firebaseio.com",
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_PROJECT_ID + ".appspot.com",
    });
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
      }
    });
  },
}).$mount("#app");
