<template>
  <div>
    <h3>Other Views</h3>
    <div>
      <div class="sectionHeader">Key open space areas</div>
      <div class="creekRoadConservationAreaMAP">
        <img
          src="@/assets/images/creekroadcons/maps/CreekRdGoogleMapLabels2021.png"
          class="img-fluid"
          alt="Wetlands"
        />
      </div>

      <div class="creekRoadConservationAreaMAP_Mobile">
        <img
          src="@/assets/images/creekroadcons/maps/CreekRdGoogleMapLabels2021_Mobile.png"
          class="img-fluid"
          alt="Wetlands"
        />
      </div>

      <a
        href="https://www.google.com/maps/place/Provincetown+Inn/@42.0444012,-70.1973713,189m/data=!3m1!1e3!4m8!3m7!1s0x89fb5862f7ced7a1:0x7f27a9d8c59d6b7d!5m2!4m1!1i2!8m2!3d42.0373257!4d-70.1958297?hl=en&authuser=0"
        target="_blank"
        ><cite>Google Maps view (2020)</cite></a
      >
    </div>

    <div class="spacer"></div>

    <div>
      <div class="sectionHeader">
        Eastern spadefoot habitat area at 21 Creek Road
      </div>
      <div class="creekRoadConservationAreaMAP">
        <img
          src="@/assets/images/PropertyDetails/21CreekRdLot6HabitatPlan20100810.png"
          class="img-fluid"
          alt="Wetlands"
        />
      </div>

      <div class="creekRoadConservationAreaMAP_Mobile">
        <img
          src="@/assets/images/PropertyDetails/21CreekRdLot6HabitatPlan20100810_Mobile.png"
          class="img-fluid"
          alt="Wetlands"
        />
      </div>
      <i>
        <a
          href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LC12I&W9RCCY=2010&W9RCMM=09&W9RCDD=03&W9CTLN=00854&WSKYCD=D&W9IMID=B10246AA.AR3"
          target="_blank"
          >Barnstable County Registry of Deeds Land Court Plan</a
        >
      </i>
    </div>

    <div class="spacer"></div>

    <div class="sectionHeader">Provincetown Tax Maps</div>

    <div class="full-image">
      <img
        src="@/assets/images/creekroadcons//maps/PtownTaxMap20211108.png"
        class="img-fluid"
        alt="Wetlands"
      />
    </div>
    <div class="mobile-image">
      <img
        src="@/assets/images/creekroadcons/maps/PtownTaxMap20211108_Mobile.png"
        class="img-fluid"
        alt="Wetlands"
      />
    </div>

    <p>
      NOTE: Grey shaded areas show where maps overlap. Originals:
      <span>
        <a
          href="http://provincetown-ma.gov/DocumentCenter/View/94/6-3?bidId="
          target="_blank"
          >Map 6-3,
        </a>
      </span>
      <span>
        <a
          href="http://provincetown-ma.gov/DocumentCenter/View/92/6-1?bidId="
          target="_blank"
          >Map 6-1
        </a>
        (plot date 5/21/2020).
      </span>
      <i><b>Wet Area</b></i> indicated at 30 Creek Road (vacant land owned by
      the Town of Provincetown).
    </p>

    <div class="spacer"></div>
  </div>
</template>

<script>
export default {
  name: "otherviews",
  components: {},
  mounted() {},
};
</script>

<style>
.spacer {
  margin-bottom: 2rem;
}

.sectionHeader {
  font-size: larger;
  font-weight: bold;
  padding: 0.4rem;
  margin-bottom: 1rem;
  color: white;
  background-color: darkorange;
}

.creekRoadConservationAreaMAP_Mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .creekRoadConservationAreaMAP {
    display: none;
  }

  .creekRoadConservationAreaMAP_Mobile {
    display: block;
  }
}
</style>
