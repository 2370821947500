import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Signin from "../components/User/Signin.vue";
import Documentation from "../views/Documentation.vue";
import SpadefootQuizHome from "../views/SpadefootQuizHome.vue";
import CreekRoad from "../views/CreekRoadConsv.vue";

import KubelSpadefootPhoto from "../views/Kubel.vue";

import AuthGuard from "../auth-guard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/docs",
    name: "docs",
    component: Documentation,
    beforeEnter: AuthGuard,
  },
  {
    path: "/spadefootquiz",
    name: "spadefootquiz",
    component: SpadefootQuizHome,
  },
  {
    path: "/kubel",
    name: "kubel",
    component: KubelSpadefootPhoto,
  },
  {
    path: "/signin",
    name: "signin",
    component: Signin,
  },
  {
    path: "/creekroad",
    name: "creekroad",
    component: CreekRoad,
    beforeEnter: AuthGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
