<template>
  <div class="spadefootquizhome">
    <SpadefootQuiz></SpadefootQuiz>
  </div>
</template>

<script>
import SpadefootQuiz from "@/components/EasternSpadefoots/SpadefootQuiz.vue";

export default {
  name: "spadefootquizhome",
  components: {
    // HelloWorld
  },
  components: {
    SpadefootQuiz,
  },
};
</script>
