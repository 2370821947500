<template>
  <div>
    <div class="sectionHeader">Provincetown Conservation Bylaws</div>

    <div>
      <h5>
        <a
          href="https://www.provincetown-ma.gov/DocumentCenter/View/322/General-Bylaw-Ch-12?bidId="
          target="_blank"
          >Chapter 12 &mdash; <i><b>Wetlands Protection Bylaw</b></i></a
        >
      </h5>
      <blockquote>
        <p>
          The purpose of this bylaw is to protect the foreshores, wetlands,
          water resources, and adjoining land areas in the Town of Provincetown
          by controlling activities deemed by the Conservation Commission likely
          to have a significant or cumulative effect upon resource area values,
          including but not limited to the following: public or private water
          supply, groundwater quantity and quality, flood control, erosion and
          sedimentation control, storm damage prevention including coastal storm
          flowage, water quality, water pollution control, fisheries, shellfish,
          wildlife habitat, <i><b>rare species habitat including</b></i> rare
          plant species, agriculture, aquaculture, and recreation values, deemed
          important to the Town (collectively, the “resource area values
          protected by this bylaw”).
          <b
            >This bylaw is intended to utilize the Home Rule authority of the
            Town to protect additional resource areas, for additional values,
            with additional standards and procedures stricter than those of the
            Massachusetts Wetlands Protection Act (G.L. Ch. 131, §40) and
            Regulations thereunder (310 CMR 10.00).</b
          >
        </p>
      </blockquote>

      <h5>
        <a
          href="https://www.provincetown-ma.gov/DocumentCenter/View/322/General-Bylaw-Ch-12?bidId="
          target="_blank"
          >Bylaws Chapter 12-9-1-3. &mdash; <i><b>Rare species</b></i></a
        >
      </h5>
      <blockquote>
        <p>
          “Rare species” shall include, without limitation, all vertebrate and
          invertebrate animal and plant species listed as endangered,
          threatened, or of special concern by the Massachusetts Division of
          Fisheries and Wildlife,
          <i
            ><b
              >regardless of whether the site in which they occur has been
              previously identified by the Division</b
            ></i
          >
          [Division of Fisheries and Wildlife].
        </p>
      </blockquote>
      <p></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "provincetownhistory",
  components: {},
  mounted() {},
};
</script>

<style>
.spacer {
  margin-bottom: 2rem;
}

.sectionHeader {
  font-size: larger;
  font-weight: bold;
  padding: 0.4rem;
  margin-bottom: 1rem;
  color: white;
  background-color: darkorange;
}

blockquote {
  display: block;
  font-style: italic;
  border-width: 2px 0;
  border-style: solid;
  border-color: #eee;
  padding: 1.5em 0 0.5em;
  margin: 1.5em 0;
  position: relative;
  margin-left: 2rem;
  margin-right: 2rem;
}

blockquote:before {
  content: "\201C";
  position: absolute;
  top: 0em;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 3rem;
  height: 2rem;
  /* font: 6em/1.08em "PT Sans", sans-serif; */
  color: #666;
  text-align: center;
}

blockquote:after {
  content: "\2013 \2003"attr(cite);
  display: block;
  text-align: right;
  /* font-size: 0.875em; */
  color: #e74c3c;
}
</style>
