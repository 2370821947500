<template>
  <div>
    <!-- <div>
      Creek Road runs from Franklin Street towards West Vine Street northwest of
      Bradford Street in the West End of Provincetown. The most southern section
      of Creek Road is undeveloped &mdash;
      <i
        >from a point just after it crosses Nickerson Street to where it ends
        near Blueberry Ave and West Vine Street</i
      >.
    </div> -->

    <div class="spacer"></div>

    <div class="full-image">
      <div class="row">
        <div class="col-2">
          <p>
        Creek Road runs from Franklin Street towards West Vine Street northwest
        of Bradford Street in the West End of Provincetown. Portions of Creek Road are undeveloped &mdash;
        <i
          >from a point just after it crosses Nickerson Street to where it intersects
          Blueberry Ave and a section that continues as a private way (easement) beyond the curve at West Vine Street</i
        >.
      </p>
        </div>
        <div class="col-6">
          <img
            src="@/assets/images/creekroadcons/maps/CreekRoadConsrvLocus_1.png"
            class="img-fluid"
            alt="Wetlands"
          />
        </div>
        <div class="col-4">
          <div class="full-image">
            <img
              src="@/assets/images/creekroadcons/CreekRoadWestVineSign_HRZ.png"
              class="img-fluid"
              alt="Wetlands"
            />
          </div>
          <div class="mobile-image">
            <img
              src="@/assets/images/creekroadcons/CreekRoadWestVineSign_HRZ_Mobile.png"
              class="img-fluid"
              alt="Wetlands"
            />
          </div>
          <p><cite>10/22/2017</cite></p>
        </div>
      </div>
    </div>

    <div class="mobile-image">
      <p>
        Creek Road runs from Franklin Street towards West Vine Street northwest
        of Bradford Street in the West End of Provincetown. Portions of Creek Road are undeveloped &mdash;
        <i
          >from a point just after it crosses Nickerson Street to where it intersects
          Blueberry Ave and a section that continues as a private way (easement) beyond the curve at West Vine Street</i
        >.
      </p>

      <img
        src="@/assets/images/creekroadcons/maps/CreekRoadConsrvLocus_1_MOBILE.png"
        class="img-fluid"
        alt="Wetlands"
      />

      <div class="spacer"></div>

      <img
        src="@/assets/images/creekroadcons/CreekRoadWestVineSign_HRZ_Mobile.png"
        class="img-fluid"
        alt="Wetlands"
      />
      <p><cite>10/22/2017</cite></p>
    </div>

    <div class="spacer"></div>

    <p>
      <i>Creek Road Conservation Area</i> is the name on file with the
      <i>Massachusetts Executive Office of Energy and Environmental Affairs</i>.
      It is shown on the following
      <a
        href="https://mass-eoeea.maps.arcgis.com/apps/webappviewer/index.html?id=3a7c475cb6d54578ba8c7149d885ad30&marker=-70.19647592113992%2C42.04439839702137%2C%2C%2C%2C&markertemplate=%7B%22title%22%3A%22Creek%20Road%20Conservation%20Area%22%2C%22longitude%22%3A-70.19647592113992%2C%22latitude%22%3A42.04439839702137%2C%22isIncludeShareUrl%22%3Atrue%7D&level=18"
        target="_blank"
        >MassWildlife Map</a
      >
      and is comprised of 2 parcels at 23 and 25 Creek Road.
    </p>

    <div class="full-image">
      <img
        src="@/assets/images/creekroadcons/MassWildlifeCreekRoadConsArea_1.png"
        class="img-fluid"
        alt="Wetlands"
      />
    </div>
    <div class="mobile-image">
      <img
        src="@/assets/images/creekroadcons/MassWildlifeCreekRoadConsArea_2a.png"
        class="img-fluid"
        alt="Wetlands"
      />
    </div>
    <p>
      <i>
        <a
          href="https://mass-eoeea.maps.arcgis.com/apps/webappviewer/index.html?id=3a7c475cb6d54578ba8c7149d885ad30&marker=-70.19647592113992%2C42.04439839702137%2C%2C%2C%2C&markertemplate=%7B%22title%22%3A%22Creek%20Road%20Conservation%20Area%22%2C%22longitude%22%3A-70.19647592113992%2C%22latitude%22%3A42.04439839702137%2C%22isIncludeShareUrl%22%3Atrue%7D&level=18"
          target="_blank"
          >Mass Wildlife Lands Viewer</a
        >
      </i>
    </p>

    <div class="spacer"></div>

    <p>
      These parcels were granted by owners Donald and Robert Fiset to the Town
      of Provincetown on January 11, 2013.
    </p>
    &mdash;
    <i
      >Barnstable County Registry of Deeds Quitclaim Deed
      <b
        ><a
          href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LR09I&W9RCCY=2013&W9RCMM=01&W9RCDD=24&W9CTLN=00638&WSKYCD=B&W9IMID=B13024AA.APO"
          target="_blank"
          >Book 27070, Page 95</a
        >.</b
      ></i
    >
    <div class="spacer"></div>
    <p>The <i>Quitclaim Deed</i> states:</p>

    <blockquote>
      Said Premises are dedicated to the public for the sole purpose of
      conservation of natural resources and passive recreation, including but
      not limited to the <b>conservation of species list</b> pursuant to
      <a
        href="https://malegislature.gov/Laws/GeneralLaws/PartI/TitleXIX/Chapter131A"
        target="_blank"
        >G.L. c. 131A et seq.</a
      >
      which shall be considered the most important conservation purpose for
      which the Premises are to be protected in perpetuity, held under the care
      and custody of the Conservation Commission, pursuant to
      <a
        href="https://malegislature.gov/Laws/GeneralLaws/PartI/TitleVII/Chapter40/Section8C"
        target="_blank"
        >G.L. c. 40 s. 8C</a
      >.
    </blockquote>

    <div class="spacer"></div>

    <div class="full-image">
      <div class="row">
        
        <div class="col-4">
          <img
            src="@/assets/images/creekroadcons\SpadefootObservations\Spadefoot1_20200629.png"
            class="img-fluid"
            alt="Eastern spadefoot in Provincetown"
          />
          <div class="citation-app">
            6/29/2020 eastern spadefoot observation in the proximity of Creek
            Road, Blueberry Avenue and West Vine Street &ndash;
            <i>Mass Wildlife Heritage Hub Observation #A32562 (7/1/2020)</i>
          </div>
        </div>
        <div class="col-8">
          <p>
            This area of West Vine Street, Creek Road, Blueberry and Bayberry
            Avenues has an interesting conservation history &ndash; especially
            as it relates to eastern spadefoot habitat. The eastern spadefoot is
            a toad-like amphibian that is listed as <b><i>threatened</i></b> in
            Massachusetts and is protected under the
            <i>Massachusetts Endangered Species Act</i> (MESA). (<a
              href="https://www.mass.gov/files/documents/2016/08/no/scaphiopus-holbrookii.pdf"
              target="_blank"
              >See the MassWildlife description for more information.</a
            >)
          </p>
        </div>
      </div>
    </div>

    <div class="mobile-image">
      <p>
        <p>
            This area of West Vine Street, Creek Road, Blueberry and Bayberry
            Avenues has an interesting conservation history &ndash; especially
            as it relates to eastern spadefoot habitat. The eastern spadefoot is
            a toad-like amphibian that is listed as <b><i>threatened</i></b> in
            Massachusetts and is protected under the
            <i>Massachusetts Endangered Species Act</i> (MESA). (<a
              href="https://www.mass.gov/files/documents/2016/08/no/scaphiopus-holbrookii.pdf"
              target="_blank"
              >See the MassWildlife description for more information.</a
            >)
          </p>
      </p>

      <img
            src="@/assets/images/creekroadcons\SpadefootObservations\Spadefoot1_20200629.png"
            class="img-fluid"
            alt="Eastern spadefoot in Provincetown"
          />
          <div class="citation-app">
            6/29/2020 eastern spadefoot observation in the proximity of Creek
            Road, Blueberry Avenue and West Vine Street &ndash;
            <i>Mass Wildlife Heritage Hub Observation #A32562 (7/1/2020)</i>
          </div>

    </div>

    <!-- <div>
      <p>
        This area of West Vine Street, Creek Road, Blueberry and Bayberry
        Avenues has an interesting conservation history &ndash; especially as it
        relates to eastern spadefoot habitat. The eastern spadefoot is a
        toad-like amphibian that is listed as <b><i>threatened</i></b> in
        Massachusetts and is protected under the
        <i>Massachusetts Endangered Species Act</i> (MESA). (<a
          href="https://www.mass.gov/files/documents/2016/08/no/scaphiopus-holbrookii.pdf"
          target="_blank"
          >See the MassWildlife description for more information.</a
        >)
      </p>

      <div>
        <img
          src="@/assets/images/creekroadcons\SpadefootObservations\Spadefoot1_20200629.png"
          class="img-fluid"
          alt="Eastern spadefoot in Provincetown"
        />
      </div>

      <div class="citation-app">
        6/29/2020 eastern spadefoot observation in the proximity of Creek Road,
        Blueberry Avenue and West Vine Street &ndash;
        <i>Mass Wildlife Heritage Hub Observation #A32562 (7/1/2020)</i>
      </div>
    </div> -->

    <div class="spacer"></div>

    <p>
      There is a cluster of properties surrounding the
      <i>Creek Road Conservation Area</i> with a variety of open space
      designations, restrictions and characteristics. The collective nature of
      these properties <i><b>potentially</b></i> creates an integrated ecosystem
      for eastern spadefoot activity and existence.
    </p>

    <!-- <blockquote>
        The collective nature of these properties <i><b>potentially</b></i> creates an integrated ecosystem for eastern spadefoot activity and existence.
      </blockquote> -->

    <div class="spacer"></div>
  </div>
</template>

<script>
export default {
  name: "introduction",
  components: {},
  mounted() {},
};
</script>

<style>
.spacer {
  margin-bottom: 2rem;
}

.citation-app {
  font-size: small;
}

.mobile-image {
  display: none;
}

@media only screen and (max-width: 768px) {
  .full-image {
    display: none;
  }

  .mobile-image {
    display: block;
  }
}
</style>
