<template>
  <div>
    <div>
      <h3>Eastern spadefoot <i>Folklore and Fact</i></h3>
      <p>
        There is a general consensus &ndash; <i>at least anecdotally</i> &ndash;
        that eastern spadefoots have inhabited this area for years. From a 2004
        Provincetown Conservation Committee public hearing related to a 72 West
        Vine Street <i>Notice of Intent</i>:
      </p>
      <!-- Minutes from the
        <a href="https://www.provincetown-ma.gov/ArchiveCenter/ViewFile/Item/456" target="_blank">5/17/2004 Conservation Committee Hearing</a> 
        related to a 72 West Vine Street <i>Notice of Intent</i> state:
        "Peter Souza informed the Commission that a spadefoot toad had been found in the area
        and that further development could be endangering that species habitat as well as that of others."
        (<a href="https://www.wickedlocal.com/story/provincetown-banner/2020/10/12/obituary-peter-souza-81-protected-clapps-pond-race-point-road-tree-canopy/42932375/" target="_blank">Peter Souza</a>
        served on the Provincetown Conservation Committee as early as 2002 and was a champion of many conservation efforts in Town).
      </p> -->

      <blockquote>
        Peter Souza informed the Commission that a spadefoot toad had been found
        in the area and that further development could be endangering that
        species habitat as well as that of others.
      </blockquote>

      <div class="citation-app">
        &ndash; Minutes from the
        <a
          href="https://www.provincetown-ma.gov/ArchiveCenter/ViewFile/Item/456"
          target="_blank"
          >5/17/2004 Conservation Committee Hearing</a
        >
      </div>

      <p>
        <a
          href="https://www.wickedlocal.com/story/provincetown-banner/2020/10/12/obituary-peter-souza-81-protected-clapps-pond-race-point-road-tree-canopy/42932375/"
          target="_blank"
          >Peter Souza</a
        >
        served on the Provincetown Conservation Committee as early as 2002 and
        was a champion of many conservation efforts in Provincetown.
      </p>

      <p>
        More fact-based and authoritative certainty has played out in official
        Town hearings and documents, on-site reviews by MassWildlife biologists
        and other sources (referenced in the <i>Property Details</i> section).
      </p>

      <p>
        Eastern spadefoots are elusive and if encountered may be mistaken for
        common frogs or toads. Special knowledge is key in the identification of
        spadefoots and a level of awareness and motivation to take action in
        reporting observations. Perhaps the most important factor in discovering
        them is great timing and even better luck.
      </p>

      <div id="spadefootQuiz">
        <p>
          MassWildlife’s
          <a
            href="https://www.mass.gov/info-details/overview-of-the-heritage-hub"
            target="_blank"
            >Heritage Hub</a
          >
          database is perhaps the defacto tool for reporting plant and animal
          observations in Massachusetts. The data collected is used to evaluate
          <i
            ><a
              href="https://www.mass.gov/service-details/regulatory-maps-priority-estimated-habitats"
              target="_blank"
              >Priority & Estimated Habitats</a
            ></i
          >
          used for regulatory purposes as specified by the
          <a
            href="https://www.mass.gov/service-details/ma-endangered-species-act-mesa-overview"
            target="_blank"
            >Massachusetts Endangered Species Act (MESA)</a
          >. In 2017, MESA <i>Estimated and Priority Habitat</i> boundaries that
          once included the <i>Creek Road Conservation Area</i> and surrounding
          portions of Provincetown were redrawn due to lack of
          <i><b>documented</b></i> observations in the prior 25 years.
        </p>

        <!-- <div>
        <button @click="$router.push('spadefootquiz')" class="btn btn-warning keypad-key">Take the Spadefoot Quiz</button>
      </div> -->

        <div>
          <!-- Using modifiers -->
          <b-button v-b-toggle.collapse-2 class="btn btn-warning keypad-key"
            >Take the Spadefoot Quiz</b-button
          >

          <!-- Element to collapse -->
          <b-collapse id="collapse-2">
            <SpadefootQuizHome></SpadefootQuizHome>
            <!-- Using value -->
            <!-- <b-button v-b-toggle="'collapse-2'" class="btn btn-warning keypad-key">Hide Quiz</b-button> -->
          </b-collapse>
        </div>
      </div>

      <p>
        It should be noted that Provincetown's Bylaws as specified in
        <a
          href="https://www.provincetown-ma.gov/DocumentCenter/View/322/General-Bylaw-Ch-12?bidId="
          target="_blank"
          >Chapter 12 &mdash; <i><b>Wetlands Protection Bylaw</b></i></a
        >
        provide for stricter <i>standards and procedures</i> than those of the
        State when it comes to protection of rare species habitat.
      </p>

      <blockquote>
        <p>
          This bylaw is intended to utilize the Home Rule authority of the Town
          to protect additional resource areas, for additional values, with
          additional
          <b
            >standards and procedures stricter than those of the Massachusetts
            Wetlands Protection Act (G.L. Ch. 131, §40) and Regulations
            thereunder (310 CMR 10.00).</b
          >
        </p>
      </blockquote>

      <div>
        <p>
          Further,
          <a
            href="https://www.provincetown-ma.gov/DocumentCenter/View/322/General-Bylaw-Ch-12?bidId="
            target="_blank"
            >Bylaws Chapter 12-9-1-3. &mdash; <i><b>Rare species</b></i></a
          >
          states:
        </p>

        <blockquote>
          <p>
            “Rare species” shall include, without limitation, all vertebrate and
            invertebrate animal and plant species listed as endangered,
            threatened, or of special concern by the Massachusetts Division of
            Fisheries and Wildlife,
            <i
              ><b
                >regardless of whether the site in which they occur has been
                previously identified by the Division</b
              ></i
            >
            [Division of Fisheries and Wildlife].
          </p>
        </blockquote>
      </div>

      <h5>June 29, 2020 Observations</h5>

      <p>
        There was a rain event during the last 3 days of June 2020 with average
        temperatures ranging between the mid-60s to 73-degrees (&#176;F). These
        are conditions that eastern spadefoots are known to favor for migration
        from upland burrows to temporary pools for breeding.
      </p>

      <p>
        3 eastern spadefoots were observed on June 29, 2020 at around 10 p.m. in
        the area of West Vine Street (ext), Creek Road and Blueberry Avenue.
        These observations were video recorded and uploaded to YouTube.
      </p>

      <div>
        <div class="d-flex flex-wrap">
          <div class="p-2">
            <iframe
              width="345"
              height="260"
              src="https://www.youtube.com/embed/dox7SzaUyT4"
            >
            </iframe>
            <p>Eastern spadefoot #1</p>
          </div>
          <div class="p-2">
            <iframe
              width="345"
              height="260"
              src="https://www.youtube.com/embed/qVjcJiCBv6s"
            >
            </iframe>
            <p>Eastern spadefoot #2</p>
          </div>
          <div class="p-2">
            <iframe
              width="345"
              height="260"
              src="https://www.youtube.com/embed/_xaHexCaN_A"
            >
            </iframe>
            <p>Eastern spadefoot #3</p>
          </div>
        </div>
        <div class="citation-app">
          &ndash; 6/29/2020 eastern spadefoot observations in the proximity of
          Creek Road, Blueberry Avenue and West Vine Street Ext., Provincetown,
          MA &ndash;
          <i>Mass Wildlife Heritage Hub Observation #A32562 (7/1/2020)</i>
        </div>
      </div>

      <!-- <div class="spacer"></div> -->

      <p>
        Unfortunately, one of the eastern spadefoots (#3) had fallen into a
        storm drain located on Blueberry Avenue. In obvious distress, it made
        distinctive utterances which were recorded and later confirmed as
        eastern spadefoot calls. The Provincetown Animal Control Officer was
        contacted the next day. By that time there were no audible sounds from
        the storm drain.
      </p>

      <p>
        On the following day, these sightings were brought to the attention of a
        renowned
        <a href="https://en.wikipedia.org/wiki/Herpetology" target="_blank"
          >herpetologist</a
        >
        who confirmed that these were eastern spadefoots.
        <!-- 2 other amphibians in the same proximity were determined to be Fowler’s toads
        (one of which had unfortunately succumbed to road mortality).
        Unlike eastern spadefoots, Fowler’s toads are not considered to be endangered or threatened in Massachusetts. -->
      </p>

      <p>
        He recommended that the observations be reviewed by a conservation
        scientist at MassWildlife (NHESP) who was contacted and agreed with his
        assessment. The observations were subsequently recorded via what is now
        <i
          ><a
            href="https://www.mass.gov/info-details/overview-of-the-heritage-hub"
            target="_blank"
            >Heritage Hub</a
          >
        </i>
        &ndash; previously the <i>Vernal Pool Reporting System (VPRS)</i>. Exact
        locations and supporting images were provided.
        <!-- <i><b>NOTE</b></i> providing exact location information via public forums is not advised to protect threatened species from harm. -->
      </p>

      <div>
        <b><i>Heritage Hub</i> Submission Details</b>
        <ul>
          <li><i>Observation #</i>: A32562</li>
          <li><i>Scientific Name</i>: Scaphiopus holbrookii</li>
          <li><i>Common Name</i>: Eastern Spadefoot</li>
          <li><i>Date Created</i>: 6/30/2020</li>
          <li><i>Last Date Updated</i>: 7/1/2020</li>
          <li><i>Date Submitted</i>: 7/1/2020</li>
          <li><i>Town</i>: Provincetown</li>
          <li><i>Site Name</i>: Creek Road, West Vine and Blueberry Ave</li>
          <li><i>Number of Observation Locations Reported</i>: 3</li>
        </ul>
      </div>

      <div>
        <p>
          MassWildlife's NHESP maintains a list of all documented MESA-listed
          species observations in Massachusetts. Searching this map by common
          name, scientific name, or town shows where rare species have been
          observed. Below is the resulting search of Provincetown for
          <i>scaphiopus holbrookii</i>, the scientific name for the eastern
          spadefoot. It shows the 2020 observations.
        </p>
        <div v-if="!getIsMobile">
          <img
            src="@/assets/images/creekroadcons/SpadefootObservations/HeritageHubSpeciesViewer20220805_2.jpg"
            class="img-fluid"
            alt="MassWildlife NHESP Rare Species Viewer"
          />
        </div>

        <div v-if="getIsMobile">
          <img
            src="@/assets/images/creekroadcons/SpadefootObservations/HeritageHubSpeciesViewer20220805_MOBILE.jpg"
            class="img-fluid"
            alt="MassWildlife NHESP Rare Species Viewer"
          />
        </div>

        <p class="citation-app">
          <a
            href="https://www.mass.gov/info-details/rare-species-viewer"
            target="_blank"
            >MassWildlife NHESP Rare Species Viewer</a
          >
        </p>
      </div>

      <h5>May 31, 2021 Observation</h5>
      <p>
        An additional observation of an eastern spadefoot near Blueberry Avenue
        from another source was made on 5/31/2021. A photo was sent to
        MassWildlife and subsequently verified as an eastern spadefoot. This
        sighting occurred during a 4-day rain event. To date, this observation
        has not been recorded via MassWildlife's <i>Heritage Hub</i> on-line
        tool.
      </p>
    </div>

    <!-- <p>
        During the last 3 days of June 2020 there was a rain event with average temperatures ranging between mid-60s to 73-degrees (F).
        Eastern spadefoots tend to favor these conditions for breeding and feeding (although more recent evidence indicates that eastern spadefoots 
        regularly emerge from their burrows during periods of no precipitation).
      </p> -->
  </div>
</template>

<script>
import SpadefootQuizHome from "@/components/EasternSpadefoots/SpadefootQuizHome.vue";
import { mapGetters } from "vuex";

export default {
  name: "creekroadspadefoothistory",
  components: {
    SpadefootQuizHome,
  },

  computed: {
    ...mapGetters(["getIsMobile"]),
  },

  mounted() {},
};
</script>

<style>
.spacer {
  margin-bottom: 2rem;
}

/* .citation-app {
    font-size: small;
    font-style: italic;
    margin-bottom: 1rem;
  } */

.creekRoadConservationAreaMAP_Mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .creekRoadConservationAreaMAP {
    display: none;
  }

  .creekRoadConservationAreaMAP_Mobile {
    display: block;
  }
}
</style>
