import Vuex from "vuex";
import Vue from "vue";

import app from "./modules/app";
import analytics from "./modules/analytics";
// import authentication from './modules/authentication';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    app,
    analytics,
  },
});
