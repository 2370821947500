<template>
  <div>
    <h2>
      Do Eastern Spadefoot Toads really exist?
    </h2>
    <img class="img-fluid" src="@/assets/images/creekroadcons/Kubel_0125_Eastern Spadefoot_website.jpg"
      alt="Eastern Spadefoot Toad">
    <p>
      <blockquote>
        This spring, be mindful of amphibians as they emerge from their winter retreats and travel to breeding sites.
        Use caution while driving on rainy spring nights.
      </blockquote>
    </p>
    <p>
      2/28/2020
      Division of Fisheries and Wildlife
      MassWildlife's Natural Heritage & Endangered Species Program
    </p>
    <a href="https://www.mass.gov/news/watch-for-amphibians-on-the-road" target="_blank">Roads risky for spadefoot
      toads</a>
    <div class="citation">Watch for amphibians on the road</div>
    <a href="https://www.capecodtimes.com/article/20080806/NEWS/808060317">Roads risky for spadefoot toads</a>
    <img class="img-fluid"
      src="https://www.capecodtimes.com/storyimage/CC/20080806/NEWS/808060317/AR/0/AR-808060317.jpg" alt="">

    <img class="img-fluid"
      src="https://www.capecodtimes.com/storyimage/CC/20080806/NEWS/808060317/H2/0/H2-808060317.jpg" alt="">

    <div class="citation">Cape Code National Seashore</div>
    <div>
      <p>
        WHEREAS, The Property contains important habitat, breeding sites, and migration routes for wildlife including
        the Eastern Spade Toad (Scaphious holbrookii), which at the time of this recording is listed as Threatened
        pursuant to the Massachusetts Endangered Species Act ( <a
          href="https://www.mass.gov/service-details/ma-endangered-species-act-mesa-overview" target="_blank">MESA</a>)
        (G.L. c. <a href="https://malegislature.gov/Laws/GeneralLaws/PartI/TitleXIX/Chapter131A/Section3"
          target="_blank">131A:3</a> and <a
          href="https://www.mass.gov/regulations/321-CMR-1000-massachusetts-endangered-species-act" target="_blank">321
          CMR 10.23</a>
      </p>
    </div>
    <div>
      <iframe width="420" height="315" src="https://www.youtube.com/embed/iQAb5N5esR4">
      </iframe>
      <p>Eastern Spadefoot (Provincetown MA, 08/23/16)</p>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'spadefoottoad',
    components: {
    }
  }
</script>

<style>
  .citation {
    margin-bottom: 1rem;
    text-align: right;
  }
</style>