<template>
  <div>
    <div style="margin: 2rem;">
      <div class="w-85 p-3" style="background-color: #eee; margin: auto;">
        <form @submit.prevent="onSignin">
          <h4>Sign In</h4>
          <div class="form-group">
            <!-- <label for="email">Email address</label> -->
            <input name="email" type="email" class="form-control" id="email" v-model="email"
              aria-describedby="emailHelp" placeholder="Enter email" required autofocus>

            <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
          </div>
          <div class="form-group">
            <!-- <label for="password">Password</label> -->
            <input name="password" type="password" class="form-control" id="password" v-model="password"
              placeholder="Password" required>
          </div>
          <button type="submit" class="btn btn-primary" :disabled="loading" :loading="loading">Sign In <span
              slot="loader" class="custom-loader"></span></button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        email: '',
        password: ''
      }
    },
    computed: {
      user() {
        return this.$store.getters.user
      },
      error() {
        return this.$store.getters.error
      },
      loading() {
        return this.$store.getters.loading
      }
    },
    watch: {
      user(value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },
    methods: {
      onSignin() {
        this.$store.dispatch('signUserIn', { email: this.email, password: this.password })
      },
      onDismissed() {
        this.$store.dispatch('clearError')
      }
    }
  }
</script>

<style scoped>

</style>