<template>
  <div class="kubel">
    <div>
      <img
        v-if="!getIsMobile"
        src="@/assets/images/SouthwickMaleSpadefoot_Kubel_20220823.jpg"
        class="img-fluid"
        alt="Eastern Spadefoot, August 23 2022 in Southwick, MA"
      />

      <img
        v-if="getIsMobile"
        src="@/assets/images/SouthwickMaleSpadefoot_Kubel_20220823_MOBILE.jpg"
        class="img-fluid"
        alt="Eastern Spadefoot, August 23 2022 in Southwick, MA"
      />
    </div>
    <div class="citation-app">J. Kubel, 8/23/2022</div>
  </div>
</template>

<script>
// import Intro from "@/views/Intro.vue";

import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {},

  computed: {
    ...mapGetters(["getIsMobile"]),
  },

  mounted: function () {
    this.$nextTick(function () {
      jQuery(".kubel").hide();
      $(".kubel").fadeIn("slow");
    });
  },
};
</script>

<style scoped></style>
