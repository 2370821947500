<template>
  <div>
    <div>
      <h3>Property Details</h3>
    </div>
    <div class="card">
      <h5 class="card-header cr-header-background-color text-white">Creek Road (partial)</h5>
      <div class="card-body">
        <h5 class="card-title">Unworked public way</h5>
        <p class="card-text">
          <div class="property-small">
            <img src="@/assets/images/PropertyImages/CreekRoad_Small.png" class="img-fluid" alt="30 Creek Road">
          </div>
          Creek Road is an unworked Town road (unpaved, undeveloped) from approximately
          the northeast corner of 30 Creek Road to 21 Creek Road.
        </p>
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-3-4-0"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>
      </div>
    </div>

    <!-- <div class="card">
      <h5 class="card-header cr-header-background-color text-white">19 Creek Road</h5>
      <div class="card-body">
        <p class="card-text">
          <div class="property-small">
            <img src="@/assets/images/PropertyImages/19CreekRoad_Small.png" class="img-fluid" alt="30 Creek Road">
          </div>
          <a
            href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LC01I&W9RCCY=2011&W9RCMM=04&W9RCDD=27&W9CTLN=00998&WSKYCD=D&W9IMID=B11117AA.AXI"
            target="_blank"
            >Declaration of Protective Covenants</a
          >
          (2011), NHESP Tracking # 07-23636.
          <i>Habitat Assessment for the Eastern Spadefoot</i> authored by Brad
          Timm, M.S., PhD. This property is “Vegetation Restricted”. (Applies to
          51 - 57 West Vine Street also)
        </p>
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-1-13-D-19A"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>
      </div>
    </div> -->

    <div class="card">
      <h5 class="card-header cr-header-background-color text-white">21 Creek Road</h5>
      <div class="card-body">
        <h5 class="card-title">
          Eastern spadefoot habitat and conservation easement
        </h5>
        <p class="card-text">
          <p>
            <div class="property-small">
            <img src="@/assets/images/PropertyImages/21CreekRoad_Small.png" class="img-fluid" alt="30 Creek Road">
          </div>
            <a href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LC12I&W9RCCY=2010&W9RCMM=09&W9RCDD=03&W9CTLN=00854&WSKYCD=D&W9IMID=B10246AA.AR3"
            target="_blank"
            >Conservation restriction established in 2010</a
          >
          as upland habitat for the eastern spadefoot, MESA designated owned by
          the Town of Provincetown.
          </p>
          <p>
            <a href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LC01I&W9RCCY=2011&W9RCMM=04&W9RCDD=27&W9CTLN=00998&WSKYCD=D&W9IMID=B11117AA.AXI"
            target="_blank"
            >Declaration of Protective Covenants</a
          >
          (2011), NHESP Tracking # 07-23636. The property (Lot #6) contains an
          easement area and habitat area for eastern spadefoots which to this
          day is contained by a split rail fence.
          See <a
            href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LC12I&W9RCCY=2010&W9RCMM=09&W9RCDD=03&W9CTLN=00854&WSKYCD=D&W9IMID=B10246AA.AR3"
            target="_blank"
            >Plan of Land</a
          >.
          </p>
        </p>
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-1-13-E"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>

        <span class="float-right assessors-note card" style="width: 18rem;">
          <h6 class="card-header"><a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2021/9596.pdf"
            target="_blank"
            >FY2021 Assessor's Notes</a
          ></h6>
          <span class="card-body">
            <p class="card-text">
              LOT 6 (MESA DESIGNATED LOT)-DEED RESTRICTED-UNBUILDABLE
            </p>
          </span>
        </span>
      </div>
    </div>

    <div class="card">
      <h5 class="card-header cr-header-background-color text-white">23 & 25 Creek Road</h5>
      <div class="card-body">
        <h5 class="card-title">Creek Road Conservation Area</h5>
        <p class="card-text">
          <div class="property-small">
            <img src="@/assets/images/PropertyImages/23_25CreekRoad_Small.png" class="img-fluid" alt="30 Creek Road">
          </div>
          MESA lots, unbuildable, owned by the Town of Provincetown Conservation
          Commission. Designated as
          <a
            href="https://mass-eoeea.maps.arcgis.com/apps/webappviewer/index.html?id=3a7c475cb6d54578ba8c7149d885ad30"
            target="_blank"
            >Creek Road Conservation Area</a
          >
          by
          <a
            href="https://www.mass.gov/orgs/division-of-fisheries-and-wildlife"
            target="_blank"
            >MassWildlife</a
          >.
          <a
            href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LR09I&W9RCCY=2013&W9RCMM=01&W9RCDD=24&W9CTLN=00638&WSKYCD=B&W9IMID=B13024AA.APO"
            target="_blank"
            >Deed Restriction</a
          >.
        </p>
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-3-50-B"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>

        <span class="float-right assessors-note card" style="width: 18rem;">
          <h6 class="card-header"><a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2021/597.pdf"
            target="_blank"
            >FY2021 Assessor's Notes</a
          ></h6>
          <span class="card-body">
            <p class="card-text">
              MESA LOT - UNBUILDABLE
            </p>
          </span>
        </span>
      </div>
    </div>

    <div class="card">
      <h5 class="card-header cr-header-background-color text-white">28-R Creek Road</h5>
      <div class="card-body">
        <h5 class="card-title">Undeveloped</h5>
        <p class="card-text">
          <div class="property-small">
            <img src="@/assets/images/PropertyImages/28RCreekRoad_Small.png" class="img-fluid" alt="30 Creek Road">
          </div>
          Undeveloped land with no known owner.
        </p>
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-3-4-A"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>

        <span class="float-right assessors-note card" style="width: 18rem;">
          <h6 class="card-header"><a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2021/519.pdf"
            target="_blank"
            >FY2021 Assessor's Notes</a
          ></h6>
          <span class="card-body">
            <p class="card-text">
              OWNERS UNKNOWN
            </p>
          </span>
        </span>
      </div>
    </div>

    <div class="card">
      <h5 class="card-header cr-header-background-color text-white">30 Creek Road</h5>
      <div class="card-body">
        <h5 class="card-title">Vacant Town-owned land</h5>
        <p class="card-text">
          <div class="property-small">
            <img src="@/assets/images/PropertyImages/30CreekRoad_Small.png" class="img-fluid" alt="30 Creek Road">
          </div>
          Vacant land, owned by the Town of Provincetown. Wetland indicated
          (Provincetown MapsOnline).
        </p>
        <p>
          Referenced in
          <a
            href="https://www.provincetown-ma.gov/DocumentCenter/View/187/Open-Space-and-Recreation-Plan-1995?bidId="
            target="_blank"
            >1995 Open Space and Recreation Plan</a
          >
          Section I.E., <i>Natural Resource Management</i>, Page 83,
          <i>
            "<b>Evaluate conservation significance</b> of town-owned Creek Road parcel,
            acquired through tax-title forelcosure. If significant, <b>dedicate to
            conservation land</b> under
            <a
              href="https://malegislature.gov/Laws/GeneralLaws/PartI/TitleVII/Chapter40/Section8C"
              target="_blank"
              >MGL c. 40, s 8C.</a
            >
            Conservation Commission & Town Meeting. 1998-99".
          </i>
        </p>
        <p>
          <!-- Assessors Note: VACANT LAND TOWN OF PROVINCETOWN TAKEN PER ART 60 1962
          ATM FOR COTTAGE ST DRAINAGE PROJECT. -->
          <a
            href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LR09I&W9RCCY=1962&W9RCMM=05&W9RCDD=14&W9CTLN=00116&WSKYCD=B&W9IMID=B11144AA.KUN"
            target="_blank"
            >1962 Quitclaim Deed and Release of Claims</a
          >.
        </p>
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-3-4-0"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>

        <span class="float-right assessors-note card" style="width: 18rem;">
          <h6 class="card-header"><a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2020/518.pdf"
            target="_blank"
            >FY2021 Assessor's Notes</a
          ></h6>
          <span class="card-body">
            <p class="card-text">
              VACANT LAND TOWN OF PROVINCETOWN
TAKEN PER ART 60 1962 ATM FOR COTTAGE ST
DRAINAGE PROJECT
            </p>
          </span>
        </span>

        <!-- <span class="float-right">
          <a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2020/518.pdf"
            target="_blank"
            class="btn cr-map-btn"
            >Assessor's Notes</a
          >
        </span> -->
      </div>
    </div>

    <div class="card">
      <h5 class="card-header cr-header-background-color text-white">19 Creek Road & 51, 53, 55, 57 West Vine Street</h5>
      <div class="card-body">
        <!-- <h5 class="card-title">Special title treatment</h5> -->
        <p class="card-text">
          <div class="property-small">
            <img src="@/assets/images/PropertyImages/19CreekRd_51_57WVine_Small.png" class="img-fluid" alt="30 Creek Road">
          </div>

          <a
            href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LC01I&W9RCCY=2011&W9RCMM=04&W9RCDD=27&W9CTLN=00998&WSKYCD=D&W9IMID=B11117AA.AXI"
            target="_blank"
            >Declaration of Protective Covenants</a
          >
          (2011), NHESP Tracking # 07-23636.
          <i>Habitat Assessment for the Eastern Spadefoot</i> authored by Brad
          Timm, M.S., PhD. This property is “Vegetation Restricted”.
        </p>

          <!-- <a
            href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LC01I&W9RCCY=2011&W9RCMM=04&W9RCDD=27&W9CTLN=00998&WSKYCD=D&W9IMID=B11117AA.AXI"
            target="_blank"
            >Declaration of Protective Covenants</a
          >
          (2011), NHESP Tracking # 07-23636. (See 19 Creek Road for other
          details).
        </p> -->
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-1-13-A-00A"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>
      </div>
    </div>

    <div class="card">
      <h5 class="card-header cr-header-background-color text-white">66 West Vine Street</h5>
      <div class="card-body">
        <!-- <h5 class="card-title">Special title treatment</h5> -->
        <div class="property-small">
          <img src="@/assets/images/PropertyImages/66WestVineSt_Small.png" class="img-fluid" alt="30 Creek Road">
        </div>
        <p class="card-text">
          This property contains significant wetlands and 100-foot wetland buffer.
        </p>
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-1-11-0"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>

        <span class="float-right assessors-note card" style="width: 18rem;">
          <h6 class="card-header"><a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2021/322.pdf"
            target="_blank"
            >FY2021 Assessor's Notes</a
          ></h6>
          <span class="card-body">
            <p class="card-text">
              INFL1 =(-.45 WETLANDS)
              FY'21- MODIFY LOT LINES W/72 W. VINE
            </p>
          </span>
        </span>

        <!-- <span class="float-right">
          <a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2021/322.pdf"
            target="_blank"
            class="btn cr-map-btn"
            >Assessor's Notes</a
          >
        </span> -->
      </div>
    </div>

    <div class="card">
      <h5 class="card-header cr-header-background-color text-white">65-R Bayberry Avenue</h5>
      <div class="card-body">
        <!-- <h5 class="card-title">Special title treatment</h5> -->
        <p class="card-text">
          <div class="property-small">
            <img src="@/assets/images/PropertyImages/65RBayberryAve_Small.png" class="img-fluid" alt="30 Creek Road">
          </div>
          <a
            href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LR01I&W9RCCY=2019&W9RCMM=03&W9RCDD=15&W9CTLN=00541&WSKYCD=B&W9IMID=B19074AA.AMQ"
            target="_blank"
            >Conservation Restriction established in 2019</a
          >
          to protect the eastern spadefoot via Declaration of Restriction, NHESP
          Tracking # 04-14086
        </p>
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-3-1-0"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>

        <span class="float-right assessors-note card" style="width: 18rem;">
          <h6 class="card-header"><a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2021/515.pdf"
            target="_blank"
            >FY2021 Assessor's Notes</a
          ></h6>
          <span class="card-body">
            <p class="card-text">
              CONSERVATION RESTRICTION ON 4499 SQ FT(0.103)
            </p>
          </span>
        </span>

        <!-- <span class="float-right">
          <a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2021/515.pdf"
            target="_blank"
            class="btn cr-map-btn"
            >Assessor's Notes</a
          >
        </span> -->
      </div>
    </div>

    <div class="card">
      <h5 class="card-header cr-header-background-color text-white">67-R Bayberry Avenue</h5>
      <div class="card-body">
        <h5 class="card-title">Bayberry Avenue Conservation Area</h5>
        <p class="card-text">
          <div class="property-small">
            <img src="@/assets/images/PropertyImages/67RBayberryAve_Small.png" class="img-fluid" alt="30 Creek Road">
          </div>
          Conservation land (8.5 acres) owned by the
          <!-- <a
                href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2020/516.pdf"
                target="_blank"
                >Provincetown Conservation Trust</a
              > -->
          <a
            href="http://www.provincetownconservationtrust.org/"
            target="_blank"
          >
            Provincetown Conservation Trust.</a
          >
          Designated as the
          <a
            href="https://mass-eoeea.maps.arcgis.com/apps/webappviewer/index.html?id=3a7c475cb6d54578ba8c7149d885ad30&marker=-70.19976430939145%2C42.04392233629828%2C%2C%2C%2C&markertemplate=%7B%22title%22%3A%22Bayberry%20Avenue%20Conservation%20Area%22%2C%22longitude%22%3A-70.19976430939145%2C%22latitude%22%3A42.04392233629828%2C%22isIncludeShareUrl%22%3Atrue%7D&level=18"
            target="_blank"
            >Bayberry Avenue Conservation Area</a
          >
          by
          <a
            href="https://www.mass.gov/orgs/division-of-fisheries-and-wildlife"
            target="_blank"
            >MassWildlife</a
          >. This property contains significant wetlands.
          <span>
            Barnstable County Registry of Deeds 
            <a
            href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LC09I&W9RCCY=2010&W9RCMM=03&W9RCDD=04&W9CTLN=01249&WSKYCD=D&W9IMID=B10064AA.ABL"
            target="_blank"
            >Certificate 190839 / Document 1135391. </a>
          </span>

          <span> 
            <a
            href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LP09I&W9RCCY=2002&W9RCMM=08&W9RCDD=04&W9CTLN=11016&WSKYCD=Q&W9IMID=B06214AA.AY1"
            target="_blank"
            >Plan of Land 35780C (Sheet8 Lot 73), ~1983.</a>
          </span>
        </p>
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-3-2-0"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>

        <span class="float-right assessors-note card" style="width: 18rem;">
          <h6 class="card-header"><a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2021/516.pdf"
            target="_blank"
            >FY2021 Assessor's Notes</a
          ></h6>
          <span class="card-body">
            <p class="card-text">
              PROVINCETOWN CONSERVATION TR
            </p>
          </span>
        </span>

        <!-- <span class="float-right">
          <a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2021/516.pdf"
            target="_blank"
            class="btn cr-map-btn"
            >Assessor's Notes</a
          >
        </span> -->
      </div>
    </div>

    <div class="card">
      <h5 class="card-header cr-header-background-color text-white">72 West Vine Street</h5>
      <div class="card-body">
        <!-- <h5 class="card-title">Special title treatment</h5> -->
        <p class="card-text">
          <div class="property-small">
          <img src="@/assets/images/PropertyImages/72WestVineSt_Small.png" class="img-fluid" alt="30 Creek Road">
        </div>
          <a
            href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LR01I&W9RCCY=2008&W9RCMM=03&W9RCDD=18&W9CTLN=01453&WSKYCD=B&W9IMID=B08079AA.ABM"
            target="_blank"
            ><i>2008 Habitat & Open Space Conservation Covenants</i></a
          >
          established for permanent <i>Open Space</i> protections and
          improvement of eastern spadefoot habitat. (See <i>65-R Bayberry Avenue</i> details for related Conservation Restriction update.)
          <!-- Assessor’s Note : INFL1 ADJ
          IS FOR WET .103 AC UNDER PERPETUAL CONSERVATION REST -->
        </p>
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-1-12-0"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>

        <span class="float-right assessors-note card" style="width: 18rem;">
          <h6 class="card-header"><a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2021/323.pdf"
            target="_blank"
            >FY2021 Assessor's Notes</a
          ></h6>
          <span class="card-body">
            <p class="card-text">
              INFL1 ADJ IS FOR
WET .103 AC UNDER PERPETUAL
CONSERVATION REST. (SEE ATTACHED DOC)
FY'21-MODIFY LOT LINE W/66 W.VINE
            </p>
          </span>
        </span>

        <!-- <span class="float-right">
          <a
            href="http://documents.provincetownma.s3.amazonaws.com/pk_cards_2021/323.pdf"
            target="_blank"
            class="btn cr-map-btn"
            >Assessor's Notes</a
          >
        </span> -->
      </div>
    </div>

    <div class="card">
      <h5 class="card-header cr-header-background-color text-white">78 West Vine Street</h5>
      <div class="card-body">
        <!-- <h5 class="card-title">Special title treatment</h5> -->
        <p class="card-text">
          <div class="property-small">
          <img src="@/assets/images/PropertyImages/78WestVineSt_Small.png" class="img-fluid" alt="30 Creek Road">
        </div>
          <a
            href="https://search.barnstabledeeds.org/ALIS/WW400R.HTM?WSIQTP=LC12I&W9RCCY=2009&W9RCMM=02&W9RCDD=13&W9CTLN=00731&WSKYCD=D&W9IMID=B09044AA.AJY"
            target="_blank"
            >Conservation restriction established in 2009</a
          >
          to protect the eastern spadefoot, groundwater supply and pollution
          prevention.
        </p>
        <span class="float-left">
          <a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-3-4-B"
            target="_blank"
            class="btn cr-map-btn"
            >Map</a
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "propertydetails",
  components: {},
  mounted() {},
};
</script>

<style>
  .btn {
    margin-bottom: 1rem;
  }

  .card {
  margin-bottom: 2rem;
}

.cr-header-background-color{
  background-color: steelblue !important;
}

.cr-map-btn {
  background-color: darkorange !important;
  color: white !important;
}

/* .cr-map-btn {
  background-color: #70906A !important;
  color: white !important;
} */

.ph-card-header {
    color: white !important;
    background-color: navy !important;
}

.heading {
  color: #fff;
  background-color: steelblue;
  padding: 1em;
}

.spacer {
  margin-bottom: 2rem;
}

.spacer-horizontal {
  margin-right: 2rem;
}

.sectionHeader {
  font-size: larger;
  font-weight: bold;
  padding: 0.4rem;
  margin-bottom: 1rem;
  color: white;
  background-color: darkorange;
}

.mobile-image {
  display: none;
}

@media only screen and (max-width: 768px) {
  .full-image {
    display: none;
  }

  .mobile-image {
    display: block;
  }
}
</style>
