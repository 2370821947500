<template>
  <div class="findingspadefoots">
    <h5>...now try finding one in the wild.</h5>
    <!-- <p>
      MassWildlife <i>Estimated and Priority Habitat</i> maps, that once
      included the <i>Creek Road Conservation Area</i> of Provincetown, were
      redrawn in 2017 due to the lack of documented observations of endangered
      or threatened species in the prior 25 years. Is it possible that not a
      single eastern spadefoot was observed in this area and "officially"
      reported via
      <a
        href="https://www.mass.gov/info-details/overview-of-the-heritage-hub"
        target="_blank"
        >Heritage Hub (VPRS)</a
      >
      in that time? Let's walk through some of the conditions that could explain
      this:
    </p> -->
    <p>
      <a
        href="https://www.mass.gov/files/documents/2016/08/no/scaphiopus-holbrookii.pdf"
        target="_blank"
        >Eastern spadefoots</a
      >
      are elusive and do not necessarily emerge from their burrows to breed
      every season. It is more likely they come out to feed on a diet of
      insects, worms <b><i>and mosquitos</i></b
      >. During their season of activity &ndash;
      <i>roughly April to September</i>
      &ndash; the habitat areas in some cases are densely vegetated on hilly
      slopes with wet and muddy landscape near ephemeral (short-lived) pools. Do
      you picture yourself wading through such areas in the middle of a rainy
      night?
    </p>
    <p>
      It's more likely that you might spot one near the roadside or driveway
      where spadefoots may seek warmth from pavement or lighted areas that
      attract insects.
    </p>
    <p>
      Assuming you are an untrained layperson (versus a
      <a href="https://en.wikipedia.org/wiki/Herpetology" target="_blank"
        >herpetologist</a
      >), picture yourself during specific warmer months of the year, late at
      night, in the rain, holding an umbrella in one hand and a flashlight in
      the other, looking down and side to side scanning the roadside for the
      silhouette or eye shine of a small toad-like creature that may be around
      an inch high.
    </p>
    <p>
      <b>How did you do on the quiz above?</b> Would you recognize the subject
      as an eastern spadefoot? Or could it be a more common relative such as a
      Fowlers Toad or an American bullfrog?
    </p>
    <p>
      Realizing your good fortune, would you then reach for your smartphone and
      make a documentation-worthy video with identifiable landmarks to prove
      that you came upon an eastern spadefoot in a specific location? By the
      way, you are now holding an umbrella, a flashlight and a phone, in the
      rain &ndash; <i>possibly in strong wind</i>.
    </p>
    <p><b>Next</b></p>
    <ul>
      <li>With whom do you share the encounter? A friend? A herpetologist?</li>
      <li>Do you know what you saw? Was it an eastern spadefoot?</li>
      <li>Do you contact MassWildlife to confirm the species?</li>
      <li>
        Are you aware that it's a threatened species and is protected in
        Massachusetts?
      </li>
      <li>
        Would you be willing to register, upload/document your observation and
        electronically sign using
        <a
          href="https://www.mass.gov/doc/heritage-hub-help-documentation/download"
          target="_blank"
          >a fairly involved (17-page) process</a
        >
        to submit your claim.
      </li>
      <li>
        Are you even aware that the Commonwealth of Massachusetts has an on-line
        reporting system &ndash;
        <a
          href="https://www.mass.gov/info-details/overview-of-the-heritage-hub"
          target="_blank"
          >Heritage Hub (VPRS)</a
        >?
      </li>
    </ul>

    <p>
      Perhaps you would try to contact the local animal control officer &ndash;
      most likely the following day during "animal control hours". The probable
      response would be, "Is it in danger?" Of course, by that time the toad,
      frog or spadefoot is long gone.
    </p>
    <p>
      The bottom line is that an extremely rare confluence of conditions needs
      to align for a legitimate observation to be made and officially recorded.
      It requires knowledge, commitment of time, persistence and
      <i>extremely good luck</i>!
    </p>

    <div class="citations">
      <p><b>Photo information</b></p>
      <ol>
        <li>
          Eastern spadefoot observed on 6/29/2020 near Creek Road in
          Provincetown, MA &ndash;
          <a href="https://www.youtube.com/embed/dox7SzaUyT4" target="_blank"
            ><i>YouTube Video</i></a
          >
        </li>
        <li>
          MassWildlife eastern spadefoot
          <a
            href="https://www.mass.gov/files/documents/2016/08/no/scaphiopus-holbrookii.pdf"
            target="_blank"
            ><i>Informational Brochure</i></a
          >
        </li>
        <li>
          Juvenile bullfrog observed on 11/12/2021 near Blueberry Avenue in
          Provincetown, MA after hopping through an open garage door
        </li>
        <li>
          Eastern spadefoot observed on 6/29/2020 near Blueberry Avenue in
          Provincetown, MA &ndash;
          <a href="https://www.youtube.com/embed/qVjcJiCBv6s" target="_blank"
            ><i>YouTube Video</i></a
          >
        </li>
      </ol>
    </div>

    <!-- <button
      @click="$router.push('/#spadefootQuiz')"
      raised
      class="btn btn-warning keypad-key"
    >
      Back to Home
    </button> -->

    <div class="spacer"></div>
  </div>
</template>

<script>
export default {
  name: "findingspadefoots",
  data() {
    return {
      temp: "",
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.spacer {
  margin-bottom: 2rem;
}

.sectionHeader {
  font-size: larger;
  font-weight: bold;
  padding: 0.4rem;
  margin-bottom: 1rem;
  color: white;
  background-color: darkorange;
}

.citations {
  font-size: small;
}
</style>
