<template>
  <div class="home">
    <div>
      <SpadefootQuiz></SpadefootQuiz>
    </div>
    <!-- <div>
      <img
        v-if="!getIsMobile"
        src="@/assets/images/SouthwickMaleSpadefoot_Kubel_20220823.jpg"
        class="img-fluid"
        alt="Eastern Spadefoot, August 23 2022 in Southwick, MA"
      />

      <img
        v-if="getIsMobile"
        src="@/assets/images/SouthwickMaleSpadefoot_Kubel_20220823_MOBILE.jpg"
        class="img-fluid"
        alt="Eastern Spadefoot, August 23 2022 in Southwick, MA"
      />
    </div>
    <div class="citation-app">J. Kubel, 8/23/2022</div> -->

    <!-- <div class="spacer"></div> -->

    <a id="back2Top" title="Back to top" href="#">&#10148;</a>
  </div>
</template>

<script>
// import Intro from "@/views/Intro.vue";

import { mapGetters } from "vuex";

import SpadefootQuiz from "@/components/EasternSpadefoots/SpadefootQuiz.vue";

export default {
  name: "Home",
  components: {
    SpadefootQuiz,
  },

  computed: {
    ...mapGetters(["getIsMobile"]),
  },

  mounted: function () {
    this.$nextTick(function () {
      jQuery(".home").hide();
      $(".home").fadeIn("slow");
    });

    /*Scroll to top when arrow up clicked BEGIN*/
    $(window).scroll(function () {
      var height = $(window).scrollTop();
      if (height > 100) {
        $("#back2Top").fadeIn();
      } else {
        $("#back2Top").fadeOut();
      }
    });
    $(document).ready(function () {
      $("#back2Top").click(function (event) {
        event.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
      });
    });
    /*Scroll to top when arrow up clicked END*/
  },
};
</script>

<style scoped></style>
