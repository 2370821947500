<template>
  <div>
    <h3>Resources</h3>
    <h5>Barnstable County Registry of Deeds Document Viewer</h5>
    <p>
      To view a Registry document, <b>select</b> <i>All Pg View the image</i> as
      indicated below.
    </p>

    <p></p>
    <div class="creekRoadConservationAreaMAP">
      <img
        src="../../assets/images/creekroadcons/HowTos/BarnstableRegOfDeedsHowTo_1.png"
        class="img-fluid"
        alt="Registry of Deeds How To"
      />
    </div>
    <div class="creekRoadConservationAreaMAP_Mobile">
      <img
        src="../../assets/images/creekroadcons/HowTos/BarnstableRegOfDeedsHowTo_1.png"
        class="img-fluid"
        alt="Registry of Deeds How To"
      />
    </div>

    <div>
      <h5>Wikipedia References</h5>
      <p>
        <a href="https://en.wikipedia.org/wiki/Scaphiopus_holbrookii" target="_blank"
          >Eastern spadefoot <i>(Scaphiopus holbrookii)</i></a
        >
      </p>
      <p>
        <a href="https://en.wikipedia.org/wiki/John_Edwards_Holbrook" target="_blank"
         >John Edwards Holbrook</i></a
        >
         (December 31, 1796 – September 8, 1871, was an American zoologist, herpetologist, physician, and naturalist)
      </p>
    </div>

    <div>
      <h5>Other</h5>
      <p>
        <a href="http://maps.massgis.state.ma.us/dfg/biomap/pdf/town_core/Provincetown.pdf" target="_blank">MassWildlife BioMap 2 &ndash; Provincetown 2012</a>
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: "resources",
  components: {},
  mounted() {},
};
</script>

<style>
.spacer {
  margin-bottom: 2rem;
}

.sectionHeader {
  font-size: larger;
  font-weight: bold;
  padding: 0.4rem;
  margin-bottom: 1rem;
  color: white;
  background-color: darkorange;
}


.creekRoadConservationAreaMAP_Mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .creekRoadConservationAreaMAP {
    display: none;
  }

  .creekRoadConservationAreaMAP_Mobile {
    display: block;
  }
}
</style>
