<template>
  <div id="app" class="container">
    <!-- https://stackoverflow.com/questions/30680697/bootstrap-navbar-100-width-not-working -->
    <div>
      <b-navbar class="b-navbar-bg" toggleable="md" type="dark">
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

        <b-collapse is-nav id="nav_collapse">
          <b-navbar-nav>
            <router-link to="/" class="nav-link" active>Home</router-link>
            <!-- <router-link v-if="userIsAuthenticated" to="/docs" class="nav-link"
              >Documentation</router-link
            > -->
            <router-link
              v-if="userIsAuthenticated"
              to="/creekroad"
              class="nav-link"
              >Creek Road Conservation Area</router-link
            >
          </b-navbar-nav>
        </b-collapse>

        <form class="form-inline my-2 my-lg-0">
          <router-link
            v-if="!userIsAuthenticated"
            to="/signin"
            class="btn btn-outline-success my-2 my-sm-0"
            >Sign In
          </router-link>

          <button
            class="btn btn-outline-warning my-2 my-sm-0"
            v-if="userIsAuthenticated"
            @click="onLogout"
          >
            Sign Out
          </button>
        </form>
      </b-navbar>

      <router-view />

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userName: {},
      window: {
        width: 0,
        height: 0,
      },
    };
  },

  components: {
    Footer,
  },

  computed: {
    ...mapGetters(["getIsMobile", "user", "isAdmin"]),
    userIsAuthenticated() {
      this.userName = this.$store.getters.user;
      // console.log("User Name: ", this.$store.getters.user);
      return (
        this.$store.getters.user !== null &&
        this.$store.getters.user !== undefined
      );
    },
  },

  methods: {
    onLogout() {
      this.$store.dispatch("logout");
      // https://router.vuejs.org/guide/essentials/navigation.html
      this.$router.push({ path: "/" });
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.setWindowWidth();
    },

    setWindowWidth() {
      // console.log('HERE...setWindowWidth')
      if (this.window.width < 768) {
        this.$store.dispatch("setIsMobile", true);
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.$store.dispatch("setIsMobile", false);
      }
    },
  },

  created() {
    // console.log('CREATED')
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
html {
  overflow-y: scroll;
}

.b-navbar-bg {
  background-color: black;
}

.nav-link {
  font-weight: bold;
}

* {
  box-sizing: border-box;
}
</style>
