<template>
  <div>
    <div class="mobile-image">
      <img
        src="@/assets/images/creekroadcons/CreekRoadConservMap_2_MOBILE.png"
        class="img-fluid"
        alt="Wetlands"
      />
    </div>

    <div class="full-image">
      <img
        src="@/assets/images/creekroadcons/CreekRoadConservMap_2.png"
        class="img-fluid"
        alt="Wetlands"
      />
    </div>

    <div>
      <p>
        <i
          ><a
            href="https://www.mapsonline.net/provincetownma/index.html?ts_cama_id=6-3-50-B#x=-7814529.539168,5167475.966663,-7813956.261456,5167765.292758"
            target="_blank"
            >Provincetown Mapsonline</a
          ></i
        >
      </p>

      <h5>Key</h5>

      <ul>
        <li>
          <b><span style="color: #FF6600;">CSV</span></b> &ndash; Conservation
          designated (MESA, conservation trust, etc.)
        </li>
        <li>
          <b><span style="color: #0000CC;">CR</span></b> &ndash; Conservation
          Restriction (CR) established on the property
        </li>
        <li>
          <b><span style="color: #0099CC;">OS</span></b> &ndash; Open Space
        </li>
        <li>
          <b><span style="color: #9800CC;">UD</span></b> &ndash; Undeveloped
          town-owned land and vacant land
        </li>
        <li>
          <b><span style="color: #990000;">VR</span></b> &ndash; Vegetation
          restricted (Eastern spadefoot habitat)
        </li>
        <li>
          <b><span style="color: #006600;">WET</span></b> &ndash; DEP and/or
          Provincetown Wetlands
        </li>
        <li>
          <b><span style="color: #006600;">WET-B</span></b> &ndash; Wetlands
          100' buffer
        </li>
        <li>
          <img
            src="@/assets/images/creekroadcons/SpadefootToad_1.gif"
            class="img-fluid spadefoot"
            alt="Eastern Spadefoot"
          />
          &ndash; Eastern spadefoot habitat restrictions
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "propertymapkey",
  components: {},
  mounted() {},
};
</script>

<style>
.spacer {
  margin-bottom: 2rem;
}

.spacer-horizontal {
  margin-right: 2rem;
}

.sectionHeader {
  font-size: larger;
  font-weight: bold;
  padding: 0.4rem;
  margin-bottom: 1rem;
  color: white;
  background-color: darkorange;
}

.spadefoot {
  max-width: 30px;
  max-height: 25px;
}

.mobile-image {
  display: none;
}

@media only screen and (max-width: 768px) {
  .full-image {
    display: none;
  }

  .mobile-image {
    display: block;
  }
}
</style>
