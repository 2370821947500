import firebase from "firebase";

const state = {
  logItems: [],
  userLogins: [],
};

const getters = {
  getLogItems: function(state) {
    return state.logItems;
  },
  getUserLogins: function(state) {
    return state.userLogins;
  },
};

const actions = {
  getAllLogItems({ state, commit }) {
    return firebase
      .database()
      .ref("bostonPropsLogItems")
      .once("value", (snapshot) => {
        commit("SET_LOG_ITEMS", snapshot.val());
      });
  },

  addLogItem({ state }, logItem) {
    firebase
      .database()
      .ref("bostonPropsLogItems")
      .push(logItem);
  },

  removeLogItem(state, index) {
    firebase
      .database()
      .ref("bostonPropsLogItems/" + index)
      .remove();
  },

  getAllUserLogins({ state, commit }) {
    return firebase
      .database()
      .ref("ptownAppLogins")
      .once("value", (snapshot) => {
        commit("SET_USER_LOGINS", snapshot.val());
      });
  },

  removeUserLogin(state, index) {
    firebase
      .database()
      .ref("ptownAppLogins/" + index)
      .remove();
  },
};

const mutations = {
  SET_LOG_ITEMS(state, logItem) {
    state.logItems = logItem;
  },

  SET_USER_LOGINS(state, userLogins) {
    state.userLogins = userLogins;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
